import { FunctionComponent } from 'react';
import { useRouter } from 'next/router';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Routes } from 'types/routes';

import { withVariables } from 'utils/string';

import Button, { ButtonElements, ButtonIconPositions, ButtonIconTypes, ButtonStyles } from 'components/layout/Button';
import LayoutContainer from 'components/layout/LayoutContainer';

import StyledComponent from './styles';
import { Props } from './types';

const PublicSectionTrialVisitVariantDefault: FunctionComponent<Props> = ({ translations, sectionSettings }) => {
    const router = useRouter();
    let productUrl: string;

    if (sectionSettings?.filters?.productSlug?.value) {
        const productSlug = sectionSettings?.filters?.productSlug?.value;
        const genderSlug = sectionSettings?.filters?.productSlug?.gender;
        const zoneByGender = {
            male: Routes.PublicMenZoneSlug,
            female: Routes.PublicWomenZoneSlug,
        };
        productUrl = (productSlug && genderSlug)
            ? withVariables(Routes.PublicProduct, { genderZoneSlug: zoneByGender[genderSlug], slug: productSlug })
            : null;
    }

    return (
        <StyledComponent className="public-section-trial-visit-variant-default">
            <LayoutContainer>
                <div className="trial-visit-container">
                    <div className="left-col">
                        <div
                            className="trial-visit-container__title"
                            dangerouslySetInnerHTML={{ __html: translations?.headline }}
                        />
                        <div
                            className="trial-visit-container__description"
                            dangerouslySetInnerHTML={{ __html: translations?.subheadline }}
                        />
                    </div>
                    <div className="right-col">
                        <Button
                            className="trial-visit-container-button"
                            element={ButtonElements.Button}
                            style={ButtonStyles.None}
                            onClick={() => router.push(productUrl || translations?.buttonHref)}
                            icon={{
                                type: ButtonIconTypes.Element,
                                value: <FontAwesomeIcon icon={faChevronRight} />,
                                position: ButtonIconPositions.After,
                            }}
                        >
                            {translations?.buttonLabel}
                        </Button>
                    </div>
                </div>
            </LayoutContainer>
        </StyledComponent>
    );
};

export default PublicSectionTrialVisitVariantDefault;
