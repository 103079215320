import styled from '@emotion/styled';

import variables from 'theme/styles/vars';

export default styled.div`
    position: relative;
    width: 100%;
    padding: ${variables.sectionPadding};

    .trial-visit-container {
        padding: 0 2em;
        display: flex;
        gap: 2em;
        justify-content: space-between;
        align-items: center;

        .left-col {
            flex: 1 1 100%;
        }

        .right-col {
            display: flex;
            flex: 1 1 100%;
            justify-content: flex-end;

			.trial-visit-container-button {
				border: 1px solid #B9944A;
            	display: inline-flex;
            	align-items: center;
            	border-radius: 4px;
            	cursor: pointer;

                .button {
                    color: #B9944A;
                }
			}
			
			@media (pointer: fine) {
                &:hover {
                    .button {
                        .button-icon-after {
                            transform: translateX(4px);
                        }
                    }
                }
            }

			.button {
                color: #414251;
                display: flex;
                gap: 5em;
                padding: 2em;

                .button-content {
                    color: currentColor;
                    font-weight: 700;
                    font-size: 18px;
                }

                .button-icon-after {
                    display: flex;
                    align-items: center;
                    color: currentColor;
                    transition: transform 200ms ease;

                    svg {
                        width: 20px;
                        height: 20px;
                        color: currentColor;
                    }

                }
            }
        }

        &__title {
            font-size: 1.7em;
            font-weight: 700;
            margin-bottom: 0.4em;
            color: #414251;
        }

        &__description {
            color: #414251;
            max-width: 490px;
        }

    }

    @media all and (max-width: ${variables.mobileL}) {
        padding: ${variables.sectionPaddingMobile};

        .trial-visit-container {
            flex-direction: column;
        }
    }

    @media all and (max-width: ${variables.mobileM}) {
        padding: ${variables.sectionPaddingMobile};

        .trial-visit-container {
            padding: 0 1em;

            &__button {
                .button {
                    gap: 2em;
                    padding: 1.4em;

                    .button-content {
                        font-size: 16px;
                        white-space: nowrap;
                    }
                }
            }
        }
    }
`;
